import React from 'react'
import whatspp from '../../Assets/images/WhatsApp.png'
import './index.css'
const Whatsapp = () => {
  return (
    
    <div className='whatsapp-icon'>
        <a href="https://wa.me/message/526HFNIOSZX6B1" target="_blank"><img src={whatspp} alt="" width='50px' /></a>
    </div>
    
  )
}

export default Whatsapp